import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Services.module.scss"

const cardData = [
  {
    heading: "I'm an",
    subHeading: "Startup.",
    description: "Don't let execution delays or talent gaps slow your growth.",
    textColor: "black",
    button: "Get Started",
    link: "/contact-us/",
  },
  {
    heading: "I'm an",
    subHeading: "Enterprise.",
    description: "Get Scalable Enterprise Solutions for your Business.",
    textColor: "white",
    button: "Explore More",
    link: "/contact-us/",
  },
]

const Services = ({ strapiData }) => {
  return (
    <div className={styles.progressSec}>
      <Container>
        <div className={styles.progress}>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          >
            {/* Backing Your Up At <span>Every Stage of your Progress</span> */}
          </h2>
          <p
            className={styles.subDescription}
            dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
          ></p>
          <Row className="gap-30">
            {strapiData?.cards?.map((item, index) => (
              <Col
                xl={6}
                lg={6}
                md={12}
                sm={12}
                key={index}
                className={styles.colGap}
              >
                <div className={`${styles.card} ${styles[`card${index + 1}`]}`}>
                  <p className={styles.heading}>{item.title}</p>
                  <h3 className={styles.subHeading}>{item.title2}</h3>
                  <p
                    className={`${styles.description} ${
                      styles[item.textColor]
                    }`}
                  >
                    {item.subTitle}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Services

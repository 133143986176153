// extracted by mini-css-extract-plugin
export var black = "Services-module--black--56217";
export var card = "Services-module--card--0c8b2";
export var card1 = "Services-module--card1--a4956";
export var card2 = "Services-module--card2--37a35";
export var card3 = "Services-module--card3--b335b";
export var colGap = "Services-module--colGap--b7e55";
export var description = "Services-module--description--0c148";
export var heading = "Services-module--heading--dc88c";
export var newBtn = "Services-module--newBtn--e300d";
export var progress = "Services-module--progress--b55fb";
export var progressSec = "Services-module--progressSec--348ca";
export var subDescription = "Services-module--subDescription--3f25f";
export var subHeading = "Services-module--subHeading--e0fbc";
export var white = "Services-module--white--ba905";
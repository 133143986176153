// extracted by mini-css-extract-plugin
export var SliderWidth = "WhyChoose-module--SliderWidth--1229e";
export var Trust = "WhyChoose-module--Trust--9120f";
export var card = "WhyChoose-module--card--43a19";
export var cardDec = "WhyChoose-module--cardDec--a8484";
export var content = "WhyChoose-module--content--ff98d";
export var heading = "WhyChoose-module--heading--1b0c7";
export var iconContainer = "WhyChoose-module--iconContainer--88b6a";
export var iconContainerLeft = "WhyChoose-module--iconContainerLeft--650ca";
export var portfolioArrowIcon = "WhyChoose-module--portfolioArrowIcon--e2afa";
export var portfolioArrowIconCover = "WhyChoose-module--portfolioArrowIconCover--02873";
export var portfolioArrowRightIconCover = "WhyChoose-module--portfolioArrowRightIconCover--54dd8";
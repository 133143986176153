// extracted by mini-css-extract-plugin
export var card = "Approch-module--card--5c619";
export var cardWrapper = "Approch-module--cardWrapper--76a8f";
export var enterpriseBuildBg = "Approch-module--enterpriseBuildBg--04add";
export var fixed = "Approch-module--fixed--e5e88";
export var headInline = "Approch-module--headInline--fca1e";
export var hire = "Approch-module--hire--95e23";
export var hireCyber = "Approch-module--hireCyber--1a5b0";
export var imgCol = "Approch-module--imgCol--423df";
export var leftCol = "Approch-module--leftCol--a4f31";
export var number = "Approch-module--number--0bec6";
export var start = "Approch-module--start--3432c";
export var subHeading = "Approch-module--subHeading--add27";